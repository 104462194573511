import { axios, axios2 } from '../utils/axios';
import {
  CountryType,
  CategoryType,
  PlatformType,
  YoutubeVideoType,
  ErrorTemplateType,
  CreateUploadFileParams,
  UploadFileParams,
  DownloadBatchFiles,
} from '@/stores/CommonStore/types';

export interface AwsConfigType {
  accessKeyId: string;
  accessKeySecret: string;
  endpoint: string;
  expire: string;
  region: string;
  stsToken: string;
}

export interface ICountriesResponse {
  list: CountryType[];
}

export interface ICategoriesResponse {
  list: CategoryType[];
}

export interface IPlatformsResponse {
  list: PlatformType[];
}

export interface IYoutubeResponse {
  etag: string;
  kind: string;
  items: YoutubeVideoType[];
}

export interface IErrorTemplateResponse {
  list: ErrorTemplateType[];
}

export interface IAppointErrorTemplateResponse {
  labelList: string[];
}

export interface IOssUrlResponse {
  id: number;
  method: string;
  url: string;
}

export interface IUploadFileResponse {
  id: number;
  url: string;
  method: string;
}

export interface IGetSingleFileInfoResponse {
  id: number;
  name: string;
  size: string;
  category: string;
  status: string;
  createdAt: string;
  // upload:
}

export interface IDownloadFileResponse {
  url: string;
}

export interface IDownloadBatchFilesResponse {
  items: DownloadBatchFiles[];
}

export interface IGetReferenceMaterialResponse {
  list: number[];
}

const commonServices = {
  getOssPolicy: () => axios.get('/interface/v1/oss/policy'),
  getAwsConfig: () => axios.get<AwsConfigType, AwsConfigType>('/interface/v1/s3/sts'),
  getCountries: () => axios2.get<ICountriesResponse, ICountriesResponse>('studio/v1/countries'),
  getCategories: () => axios2.get<ICategoriesResponse, ICategoriesResponse>('studio/v1/categories'),
  getPlatforms: () => axios2.get<IPlatformsResponse, IPlatformsResponse>('studio/v1/platforms'),
  getYoutubeVideo: (params: any) =>
    axios2.get<IYoutubeResponse, IYoutubeResponse>('https://www.googleapis.com/youtube/v3/videos', {
      params,
      baseURL: '',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),

  getErrorTemplates: (params: any) =>
    axios.get<IErrorTemplateResponse, IErrorTemplateResponse>('template/v1/all', { params }),
  getAppointErrorTemplates: (params: any) =>
    axios.get<IAppointErrorTemplateResponse, IAppointErrorTemplateResponse>(
      'template/v1/template',
      { params }
    ),

  // 创建上传文件素材 Create object
  createUploadFile: (params: CreateUploadFileParams) =>
    axios.post<IUploadFileResponse, IUploadFileResponse>('/strand/v1/objects', params),

  // 上传文件素材
  uploadFile: (params: UploadFileParams) =>
    axios2.put(params.url, params.file, {
      baseURL: '',
      timeout: 86400000,
      headers: {
        'X-Oss-Meta-Id': params.ossMetaId,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': '',
        'Content-Disposition': `attachment; filename="${encodeURI(params.name)}"`,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / (progressEvent.total || 0)) * 100);
        params.onProgress && params.onProgress(progress);
        return progress;
      },
    }),

  // 下载单个文件 download object
  downloadSingleFile: (uid: number, oid: number) =>
    axios.post<IDownloadFileResponse, IDownloadFileResponse>(`/strand/v1/objects/${oid}:download`, {
      headers: {
        'Content-Type': 'application/json',
      },
      uid,
    }),

  // 下载批量文件 download object
  downloadBatchFiles: (uid: number, oids: number[]) =>
    axios.post<IDownloadBatchFilesResponse, IDownloadBatchFilesResponse>(
      `/strand/v1/objects:batchDownload`,
      { uid, oids }
    ),

  // 批量删除文件 batch delete objects
  deleteBatchFiles: (uid: number, oids: number[]) =>
    axios.post('/strand/v1/objects:batchDelete', { uid, oids }),

  // 修改名称
  updateMaterialName: (oid: number, name: string) =>
    axios.patch(`/strand/v1/objects/${oid}`, { name }),

  // 查询引用中的素材
  getReferenceMaterial: () => axios.get<any, any>('/video/v1/object/reference'),
};

export default commonServices;
