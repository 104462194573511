const zh = {
  "global": {
    "language": "CN",
    "customer_service": "客服",
    "customer_account_tips": "⚠️如果这不是您的账号，您可能会被限制使用该服务。",
    "customer_service_tips": "如果有问题,请联系[{0}]🧏‍♀️",
    "customer_service_subtitle": "请尽可能的详细描述您所遇到的问题，通过{0}联系我们。",
    "customer_service_link": "客服中心",
    "customer_service_email": "客服邮箱",
    "email_holder": "请输入邮箱",
    "email_error": "邮箱格式错误！",
    "password_holder": "请输入密码",
    "password_error": "8-20字以上的大小写英文，数字，特殊文字（都要包括）",
    "password_confirm_error": "两次输入密码不相同！",
    "phone_holder": "请输入手机号",
    "code_holder": "请输入验证码",
    "code_error": "验证码格式错误！",
    "code_get": "获取验证码",
    "code_resend": "重新发送",
    "agreement_all_agree": "全部同意",
    "agreement_age_14": "本人满14岁以上",
    "agreement_age_18": "本人满18岁以上",
    "protocol_checkbox_user": "{0}用户协议",
    "protocol_checkbox_privacy": "{0}隐私协议",
    "agreement_message_tip": "需要先同意协议",
    "btn_add": "添加",
    "btn_change": "修改",
    "btn_delete": "删除",
    "btn_confirm": "确认",
    "btn_consider": "再考虑下",
    "btn_close": "关闭",
    "btn_submit": "提交",
    "btn_cancel": "取消",
    "btn_know": "知道了",
    "btn_check": "查看",
    "btn_view_more": "查看更多",
    "btn_last_step": "上一步",
    "btn_next_step": "下一步",
    "btn_agree": "同意",
    "btn_disagree": "不同意",
    "btn_go": "前往",
    "btn_signin": "登录",
    "btn_google_signin": "使用谷歌登录",
    "btn_send": "发送",
    "btn_verify": "验证",
    "btn_done": "完成",
    "btn_save": "保存",
    "btn_put_away": "收起",
    "btn_expand": "展开",
    "btn_edit": "编辑",
    "btn_view_in_mobile": "使用手机浏览",
    "modal_open_platform_title": "请先进行平台开通",
    "modal_open_platform_subtitle": "立即去开通平台，享受完整的用户体验！",
    "modal_open_platform_btn": "去开通",
    "total_count": "共{0}条数据",
    "list_no_more": "已加载全部数据",
    "message_success_create": "创建成功！",
    "message_error_create": "创建失败！",
    "message_success_update": "修改成功！",
    "message_error_update": "修改失败！",
    "message_success_delete": "删除成功！",
    "message_error_delete": "删除失败！",
    "message_success_upload": "上传成功！",
    "message_error_upload": "上传失败！",
    "message_success_bind": "绑定成功!",
    "message_error_bind": "绑定失败!",
    "message_no_token": "登录超时，请重新登录！",
    "message_no_permission": "没有权限！",
    "message_sms_sended": "短信验证码已发送！",
    "message_sms_error": "短信验证码发送失败！",
    "message_email_sended": "邮箱验证码已发送！",
    "message_email_error": "邮箱验证码发送失败！",
    "message_verify_success": "验证成功！",
    "message_verify_fail": "验证失败！",
    "message_code_invalid": "验证码错误或已失效！",
    "tooltip_developing": "功能升级中，敬请期待",
    "not_found_title": "没有您要找的页面",
    "not_found_tips": "可能是路径错误导致",
    "empty_no_data": "暂无数据",
    "empty_on_dev": "敬请期待",
    "upgrading_tip": "功能升级中，敬请期待",
    "upload_avatar": "上传头像",
    "device_reminder_content": "本网站针对桌面浏览器进行了优化。在移动设备上连接时，某些功能可能会受到限制。",
    "trial_watermark": "adobaro体验版",
    "platform_hao_kan": "好看视频",
    "platform_tencent": "腾讯视频",
    "platform_iqiyi": "爱奇艺",
    "platform_bilibili": "哔哩哔哩",
    "platform_xi_gua": "西瓜视频",
    "platform_wei_bo": "微博",
    "platform_youtube": "Youtube",
    "platform_dou_yin": "抖音",
    "platform_red_book": "小红书",
    "platform_kuai_shou": "快手",
    "platform_cloud_music": "网易云音乐",
    "platform_wechat_video": "微信视频号",
    "platform_mei_pai": "美拍",
    "platform_da_yu": "大鱼号",
    "platform_dian_tao": "点淘",
    "platform_qq_world": "QQ小世界",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "country_USA": "美国",
    "country_CAN": "加拿大",
    "country_KRW": "韩国",
    "country_CHN": "中国"
  },
  "dashboard": {
    "home": "仪表板",
    "members": "成员管理",
    "wallet": "我的钱包",
    "setting": "设置",
    "close_sidebar": "收起侧边栏",
    "customer_service": "客服中心",
    "lang_language": "语言",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "sign_out": "退出登录"
  },
  "sign": {
    "signin_intro_title": "You Create, \nWe Navigate. ",
    "signin_intro_mcn": "MCN, 代理机构管理解决方案",
    "signin_intro_mcn_subtitle": "一次管理多个创作成员者的多个频道。",
    "signin_intro_operation": "安全 & 智能操作",
    "signin_intro_operation_subtitle": "AI人工智能驱动: \n覆盖内容优化到自动分发",
    "signin_login_title": "登录",
    "signin_enter_password": "输入密码",
    "password_check_1": "8-20个字符",
    "password_check_2": "大小写字母",
    "password_check_3": "至少一个特殊字符和数字",
    "password_incorrect_format": "密码格式错误",
    "signin_title": "欢迎 adobaro Partner ",
    "signin_subtitle": "MCN/Agency 的解决方案！\n我们支持您作为达人的每一步成长",
    "email_holder": "请输入邮箱",
    "email_error": "邮箱格式错误！",
    "forgot_password": "忘记密码?",
    "signup": "注册",
    "signup_tip": "还没有账号？立即{0}",
    "account_not_exist": "找不到信息,请重新确认登录ID",
    "signin_error": "登录失败",
    "google_signin_error": "谷歌登录失败",
    "signin_warning": "我们发现您的登录存在安全问题，您需要查看您的电子邮件并验证您的安全性",
    "signin_fail": "密码错误",
    "too_many_requests": "您请求的次数太多,请稍等片刻",
    "modal_signup_tips1": "我们已经发送了一封电子邮件，其中包含设置您密码的链接。",
    "modal_signup_tips2": "请检查您的收件箱并设置您的密码",
    "modal_signup_tips3": "*检查您的垃圾邮件，以防您没有收到电子邮件。",
    "modal_signup_btn": "确认",
    "modal_welcome_tips1": "欢迎加入adobaro Enterprise！",
    "modal_welcome_tips2": "管理员确认后，将对订阅进行批准处理。",
    "modal_welcome_tips3": "批准结果通过电子邮件发送，请参考邮件。",
    "modal_welcome_btn": "我已确认",
    "modal_forgot_title": "找回密码",
    "modal_forgot_tips1": "确认接收带有密码重置链接的电子邮件",
    "modal_forgot_tips2": "检查您的电子邮件收件箱并重置密码",
    "modal_forgot_tips3": "*检查您的垃圾邮件，以防您没有收到电子邮件。",
    "modal_forgot_btn": "开始重设密码",
    "modal_wait_title": "账户未审批",
    "modal_wait_tips1": "管理员正在检查账户。",
    "modal_wait_tips2": "进行结果通过电子邮件指导，请参考邮件。\n\n",
    "modal_wait_tips3": "如果需要很多时间，请通过下面的咨询按钮\r\n请咨询负责人",
    "modal_wait_btn": "咨询",
    "forgot_email_send": "邮件已发送,请前往邮箱进行下一步操作",
    "forgot_email_send_error": "发送邮件失败",
    "verify_success": "验证成功",
    "verify_fail": "验证失败",
    "verify_success_tip": "您已验证成功，账号已解除安全登录限制。",
    "verify_fail_tip": "请检查您的链接或验证已超时。",
    "btn_signin_retry": "重新登录",
    "signup_intro_title": "You Create, \nWe Navigate. ",
    "account_exist": "账号已注册",
    "license_no_holder": "请输入营业执照号码",
    "invitation_code_holder": "请输入邀请码",
    "license_or_code_error": "营业执照号码或邀请码不正确! 无邀请码请{0}。",
    "invitation_code_tip": "没有收到邀请码? 去{0}",
    "invitation_code_tip_text": "咨询",
    "btn_signup": "创建账户",
    "signup_age_14": "本人满14岁以上",
    "signup_agree_tips": "创建即同意我们的{0}和{1}",
    "signup_agree_tips_terms": "用户协议",
    "signup_agree_tips_privacy": "隐私政策",
    "signup_marketing_protocol": "(可选) 营销通知",
    "signin": "登录",
    "signin_tip": "已有账号？去{0}",
    "signup_email_send": "邮件已发送,请前往邮箱进行下一步操作",
    "signup_email_send_error": "创建账户失败",
    "password_intro_title": "You Create, \nWe Navigate. ",
    "password_set": "设置密码",
    "password_reset": "设置新密码",
    "password_set_subtitle": "通过邮箱已经认证过本人操作，请设置密码",
    "password_reset_subtitle": "通过邮箱已经认证过本人操作，请设置新密码",
    "btn_password_set": "设置密码",
    "btn_password_reset": "重置密码",
    "password_set_success": "设置密码成功",
    "password_reset_success": "重置密码成功",
    "password_set_fail": "设置密码失败",
    "password_reset_fail": "重置密码失败",
    "password_set_code_invalid": "验证码已失效,请重新注册",
    "password_reset_code_invalid": "验证码已失效,请重新重置密码"
  },
  "home": {
    "invite_label": "邀请成员",
    "total_platform_overview_title": "全平台数据概览",
    "total_members": "总成员",
    "total_platform_fans": "全平台总粉丝数",
    "total_platform_plays": "全平台总播放量",
    "check_more": "查看更多",
    "add_member": "邀请成员",
    "earnings_overview_title": "收益概览",
    "total_earnings": "累计总收入",
    "settled_earnings": "已结算总收入",
    "balance_earnings": "收入余额",
    "total_tips": "到目前为止获得的总收益",
    "settled_tips": "到目前为止结算的总金额",
    "balance_tips": "未结算总收益的余额",
    "tour_step1": "您可以点击按钮进行成员邀请",
    "tour_step2": "您可以通过UID搜索邀请member成员",
    "tour_step3": "您可以查看您所属的成员的信息。",
    "tour_step4": "您可以查看成员、粉丝和观看量等摘要信息。",
    "tour_step5": "您可以查看利润、结算金额、余额等",
    "settle_title": "附属成员将获得Pro福利，下个月预支付的金额将根据附属会员的数量而变化",
    "settle_clause": "● 第一个月：基本费用（最低金额） $490\n● 若成员人数超过10人，将按成员人数收费（会员人数 x $49）\n● 但是，随着成员数量的增加，折扣率会提高",
    "settle_current_members": "目前成员人数：",
    "settle_discount_rate": "折扣率：",
    "settle_estimate_next_amount": "预估下次的付款金额：",
    "settle_estimate_tips": "以确定值为准",
    "settle_discount_btn": "折扣率详情",
    "settle_example_label": "例子：",
    "settle_example1_date": "1月15日",
    "settle_example1_content": "初始注册\r\n第一个月($490)",
    "settle_example2_date": "下一个付款日D-7日",
    "settle_example2_content": "根据会员人数确定下次付款金额",
    "settle_example3_date": "2月15日",
    "settle_example3_content": "下一个付款日",
    "settle_last_amount": "之前的付款日\n付款金额：",
    "settle_next_amount": "下次的付款金额：",
    "settle_D_7_members": "D-7日成员数：",
    "settle_register_btn": "Enterprise partner 注册",
    "settle_help_center_link": "详情请参阅帮助中心",
    "beta_modal_title": "当前版本为beta测试版，如有支付相关疑问，请咨询adobaro负责人员",
    "discount_member": "Member成员数",
    "discount_rate": "折扣率",
    "discount_amount": "最终金额",
    "discount_each_person": "每人{0}",
    "discount_more_then": "{0}以上",
    "discount_negotiate": "另作单独商议"
  },
  "member": {
    "search_label": "我的成员",
    "search_placeholder": "请输入成员完整的uid进行搜索",
    "invite_btn": "邀请成员",
    "add_placeholder": "请输入成员完整的uid进行搜索",
    "fans_number": "粉丝数:",
    "add_invite": "邀请",
    "add_cooperation": "合作中",
    "add_invited": "已邀请",
    "add_other": "已合作其他机构",
    "add_undefined": "该账户不存在，请重新搜索",
    "add_modal_title": "邀请成员",
    "invite_success": "邀请发送成功！",
    "invite_fail_cooperated": "该成员已合作其他机构，无法发起邀请",
    "invite_fail": "邀请发送失败，请稍后重试！",
    "cancel_success": "取消成功！",
    "cancel_fail": "取消失败，请稍后重试！",
    "no_members": "您还没有成员，请立即邀请成员",
    "search_empty": "没有找到该成员，请重新搜索",
    "radio_all": "全部",
    "radio_fans": "最多粉丝",
    "radio_plays": "最多播放",
    "radio_alphabet": "字母顺序",
    "total_member": "总{0}成员",
    "account_name": "账号名称",
    "invite_status": "邀请状态",
    "open_platform": "开通平台",
    "total_subscriptions": "总订阅数",
    "total_plays": "总播放量",
    "total_income": "总收益数",
    "action": "操作",
    "invite_status_success": "邀请成功",
    "invite_status_wait": "邀请等待接受",
    "invite_status_refuse": "邀请被拒绝",
    "invite_status_cancel": "邀请已取消",
    "invite_cancel_btn": "取消邀请",
    "invite_again_btn": "再次邀请",
    "modal_cancel_title": "确认取消邀请吗？",
    "modal_cancel_content": "取消后，将无法恢复，请谨慎操作！",
    "modal_delete_title": "确认删除吗？",
    "modal_delete_content": "删除后，将无法恢复，请谨慎操作！",
    "modal_invite_title": "您确定要发送邀请吗？",
    "modal_invite_content": "请再次确认对方是否为组织所属成员。如果您邀请的用户不是你组织的成员，您可能需要对将来可能出现的任何问题负责。",
    "modal_ok_invite": "邀请",
    "modal_cancel_next": "下次吧",
    "opened_platforms": "开通平台",
    "platform_management": "管理",
    "all_platforms_fans": "全平台粉丝数",
    "all_platforms_plays": "全平台播放量",
    "all_data_statistic": "{0}全平台数据统计",
    "upload_video": "上传视频",
    "upload_video_tips": "平台还没有开通，\n请先在成员账号上开通平台。",
    "latest_publish": "最新发布",
    "earnings_overview": "收益概览",
    "earnings_total_amount": "累计总收入",
    "earnings_withdrawal_amount": "已结算总收入",
    "earnings_balance_amount": "收入余额",
    "earnings_detail": "收益详情",
    "platform_title": "平台概览",
    "platform_info_title": "基础信息",
    "platform_info_avatar": "头像",
    "platform_info_intro": "简介",
    "platform_info_intro_empty": "暂无简介",
    "platform_info_intro_holder": "请输入简介",
    "platform_info_date": "账号注册时间",
    "platform_info_date_empty": "暂无数据",
    "platform_info_date_holder": "请选择注册时间",
    "platform_info_classify": "账号分类",
    "platform_info_classify_empty": "暂无数据",
    "platform_info_classify_holder": "请选择分类",
    "platform_info_income": "平台收益",
    "platform_info_order": "接单",
    "platform_info_btn_again": "再次推荐",
    "platform_info_btn_open": "立即开通",
    "platform_info_btn_edit": "修改基础信息",
    "platform_info_btn_submit": "保存并修改",
    "platform_data_overview_title": "数据概览",
    "platform_data_radio_yesterday": "昨天",
    "platform_data_radio_nearly7": "近7天",
    "platform_data_radio_nearly30": "近30天",
    "platform_data_fans": "累计粉丝数",
    "platform_data_likes": "累计点赞数",
    "platform_data_platform": "累计平台收益",
    "platform_data_published": "累计发布",
    "platform_data_plays": "累计播放数",
    "platform_data_ad": "累计广告收益",
    "platform_release_video_title": "发布视频",
    "content_management": "内容管理",
    "content_btn_all": "全部",
    "content_btn_play_most": "最多播放",
    "content_btn_like_most": "最多点赞",
    "content_detail_title": "视频详情",
    "content_base_info": "基础信息",
    "content_base_video": "视频",
    "content_base_cover": "封面",
    "content_base_title": "标题",
    "content_base_subtitle": "字幕",
    "content_base_upload_date": "上传时间",
    "content_empty_cover": "暂无封面",
    "content_mcn_upload": "MCN上传",
    "content_no_data": "原始文件已从文件存储中删除。",
    "content_under_review": "正在针对视频内容进行AI自动检测，请耐心等待",
    "content_in_failure": "视频内容AI智能检测结果显示有内容待优化，请修改后重新上传",
    "content_distributed_platform": "发布状态",
    "content_distributed_name": "平台",
    "content_distributed_state": "状态",
    "content_distributed_subtitle": "字幕",
    "content_distributed_plays": "播放数",
    "content_distributed_likes": "点赞数",
    "content_distributed_upload_date": "发布时间",
    "content_distributed_action": "操作",
    "content_status_playing": "发布成功",
    "content_status_opening": "审核中",
    "content_status_failure": "发布失败",
    "content_status_unpublished": "可发布",
    "content_status_not_opened": "未开通",
    "content_status_frozen": "已冻结",
    "content_status_deleting": "删除中",
    "content_status_deleted": "已删除",
    "content_action_operate": "链接",
    "content_action_ask": "询问",
    "content_action_publish": "上传",
    "content_action_waiting_open": "等待开通",
    "content_action_check": "查看",
    "content_action_analysis": "分析",
    "content_action_edit": "修改",
    "content_action_delete": "删除",
    "content_action_unfreeze": "解冻",
    "content_delete_video_title": "申请删除后视频将无法恢复,是否确认删除?",
    "content_delete_video_success": "申请删除视频成功!",
    "content_delete_video_error": "申请删除失败失败!",
    "content_republish_success": "发布成功",
    "content_republish_error": "发布失败",
    "content_republish_error_insufficient": "发布失败，可用发布次数不足",
    "content_republish_can": "您是否想要向{0}频道上传内容？剩余次数：{1}次。",
    "content_republish_cannot": "{0}平台发布视频剩余次数为{1}次，无法提交发布视频。",
    "content_republish_certify_warning": "上传到该平台需要平台状态已认证",
    "content_video_url_error": "获取视频链接失败",
    "content_failure_reason": "失败原因",
    "content_distributed_state_intro": "上传成功：内容审查和上传过程已经完成。\n审核中：正在进行各平台内容审查和上传所需的必要程序。\n可上传：频道已开设，且可以申请上传相应内容。\n无可用频道：频道尚未开设。"
  },
  "platform": {
    "guide_title": "是否有运营账号?",
    "guide_subtitle": "输入您的账号 ID，我们将根据人工智能分析为您推荐最佳平台。",
    "guide_platform_holder": "请选择平台",
    "guide_platform_url_holder": "请输入Channel ID",
    "guide_platform_url_error": "您输入的Channel ID有误,请检查您的Channel ID",
    "guide_platform_already": "此Channel ID已被注册!",
    "guide_platform_confirm": "Channel ID提交后不可修改.\n请检查并确认您填写的Channel ID!",
    "guide_platform_tip": "怎么查看运营账号的Channel ID?",
    "guide_btn_recommend": "推荐",
    "guide_platform_error": "推荐平台失败",
    "guide_skip_title": "暂时跳过开通平台",
    "guide_skip_subtitle": "这里是选择原因的说明",
    "guide_skip_holder": "请选择跳过原因",
    "guide_skip_reason_1": "没有运营账号",
    "guide_skip_reason_2": "已经开通过账号",
    "guide_skip_reason_3": "没有想要的国家平台",
    "guide_skip_reason_4": "其他原因",
    "guide_skip_btn": "进入首页",
    "guide_analysis_title": "分析中",
    "guide_analysis_subtitle": "我们将对您的渠道进行分析，并推荐最适合您的平台。",
    "guide_analysis_fail_title": "目前太多人排队",
    "guide_analysis_fail_subtitle": "分析完毕后，通过邮箱通知您",
    "guide_analysis_fail_btn_next": "下次吧",
    "guide_analysis_fail_btn_confirm": "通知我",
    "guide_analysis_done_title": "账号分析完成",
    "guide_analysis_done_subtitle": "从现在起您上传的任何内容都将会对该平台进行自动优化。",
    "guide_analysis_platform_about": "了解平台",
    "guide_analysis_btn_open": "申请开通",
    "guide_analysis_btn_already": "已有相关平台账号",
    "guide_analysis_btn_next": "下次开通",
    "guide_recommend_fail": "开通平台失败",
    "guide_bind_title": "绑定账号",
    "guide_bind_subtitle": "绑定运营账号，以便后续运营平台",
    "guide_bind_tip": "为什么需要账号密码？",
    "guide_bind_btn_confirm": "立即绑定",
    "guide_bind_btn_next": "下次绑定",
    "guide_bind_success": "您已提交平台信息,稍后将邮件通知您绑定结果",
    "guide_open_phone_title": "注册号码",
    "guide_open_phone_subtitle": "请输入您自己的手机号码。",
    "guide_enter_phone": "手机号码",
    "guide_open_btn_verify": "下一步",
    "guide_open_send_sms_error": "发送验证码失败",
    "guide_open_verify_title": "填写验证码",
    "guide_open_verify_subtitle": "单击 \"发送验证码”按钮发送验证码。请等待几分钟后再接收验证码，因为根据系统状态，验证码可能会延迟发送。短信示例：",
    "guide_open_btn_verifying": "验证",
    "guide_open_verify_error": "验证失败",
    "manage_title": "平台管理",
    "manage_subtitle": "(✿◡‿◡)在 adobaro 中轻松安全地开通平台。我们将准确、安全地为您处理创建和认证平台的所有复杂问题。 ✌️' ֊'",
    "applied_platform": "已申请平台",
    "btn_refresh": "刷新平台状态",
    "btn_refresh_tip": "点击刷新平台开通情况",
    "pending_tip_content": "按照您输入的手机号即将开通平台。按钮“refresh”显示“验证码”的时候，进来验证页面",
    "btn_all": "全部",
    "btn_play_most": "最多播放",
    "btn_like_most": "最多点赞",
    "platform_empty": "开始平台申请",
    "platform_frozen_tip": "点击查看或解锁",
    "platform_view_btn": "查看平台",
    "status_done": "已开通/已认证",
    "status_opening": "开通中",
    "status_verifying": "验证中",
    "status_verify_failed": "验证失败",
    "status_failed": "开通失败",
    "status_unbind": "已开通,待认证",
    "status_binding": "已开通,认证中",
    "status_bound_fail": "认证失败",
    "btn_status_done": "发布视频",
    "btn_status_failed": "查看详情",
    "status_opening_intro": "开通中 : 您正在创建一个频道。",
    "status_unbind_intro_1": "已开通，待认证 : 频道创建过程已完成，正在根据各平台的规则进行必要的验证过程，包括身份验证、所有权验证、频道验证和一致性检查。",
    "status_unbind_intro_2": "首次上传需要完成验证流程。",
    "status_unbind_intro_3": "*视平台而定，总共需要 5-30天",
    "status_done_intro": "已开通/已认证 : 通道创建和验证过程已完成。",
    "un_apply_platform": "未申请平台",
    "open_count": "{0} 名用户已经开通",
    "lastest_open": "用户{0} {1}开通",
    "btn_open": "一键开通",
    "btn_reapply": "重新申请",
    "open_platform_title": "开通平台",
    "btn_contact_us": "联系我们",
    "platform_recommend": "推荐",
    "platform_disable": "检测中",
    "platform_disable_title": "提示",
    "platform_disable_content": "由于{0}平台系统维护，平台创建服务已暂停。如有任何疑问，请联系客服中心。🧏‍♀️",
    "platform_conditions_required": "🚨 申请无法通过：您未满足开通频道的申请条件。请检查申请条件后再次尝试。",
    "modal_bound_fail_title": "认证失败 👀",
    "modal_bound_fail_content": "请检查以下内容\n\n⚠️ 原因：{0}",
    "modal_bound_fail_btn": "去认证",
    "open_platform_subtitle": "adobaro 能够准确、安全地处理创建和认证渠道的所有复杂问题",
    "details_title": "平台概览",
    "details_info_title": "基础信息",
    "details_info_avatar": "头像",
    "details_info_intro": "简介",
    "details_info_intro_empty": "暂无简介",
    "details_info_intro_holder": "请输入简介",
    "details_info_date": "账号注册时间",
    "details_info_date_empty": "暂无数据",
    "details_info_date_holder": "请选择注册时间",
    "details_info_classify": "账号分类",
    "details_info_classify_empty": "暂无数据",
    "details_info_classify_holder": "请选择分类",
    "details_info_income": "平台收益",
    "details_info_order": "接单",
    "details_info_btn_again": "再次推荐",
    "details_info_btn_open": "立即开通",
    "details_info_btn_edit": "修改基础信息",
    "details_info_btn_submit": "保存并修改",
    "details_data_overview_title": "数据概览",
    "details_data_radio_yesterday": "昨天",
    "details_data_radio_nearly7": "近7天",
    "details_data_radio_nearly30": "近30天",
    "details_data_fans": "累计粉丝数",
    "details_data_likes": "累计点赞数",
    "details_data_platform": "累计平台收益",
    "details_data_published": "累计发布",
    "details_data_plays": "累计播放数",
    "details_data_ad": "累计广告收益",
    "details_release_video_title": "发布视频",
    "trial_modal_title": "adobaro体验版协议",
    "trial_modal_subtitle": "在使用adobaro试用版之前，请务必阅读以下内容！",
    "trial_modal_btn_agree": "同意并开始",
    "trial_protocol_title": "请确保您已经了解并同意上述内容，祝您体验愉快。",
    "trial_msg_toggle_error": "进入体验版失败！",
    "trial_msg_certified_error": "您已通过Global Pass认证，不能进入体验版！",
    "trial_protocol_item_1": "所有测试用户将共享您在试用版上传的内容，请您在上传前确保内容已采取必要的版权保护措施。",
    "trial_protocol_item_2": "试用版上传的内容将在次日全部重置。",
    "trial_protocol_item_3": "试用版显示的播放量数据是为试用版创建的通道的数据，该数据仅为测试所用。",
    "trial_protocol_item_4": "原则上，试用版的所有功能与正式版相同，最终功能以正式版为准。",
    "trial_protocol_item_5": "为了顺利体验，请注意部分功能不受支持。",
    "trial_protocol_subtitle_2": "请确保您已经了解并同意上述内容，祝您体验愉快。",
    "guide_exit_opening_platform": "退出当前流程会丢失输入的信息，下次开通会重新进入申请流程哦~",
    "guide_exit_cancel": "取消",
    "guide_exit_confirm": "确认",
    "guide_submit_success": "提交成功",
    "guide_waiting_phone_code": "注册将在3-5分钟后完成，请耐心等待",
    "guide_know_btn": "我知道了",
    "guide_change_account": "更换账号",
    "guide_phone_code_verify": "验证码验证",
    "guide_pass_verify": "护照验证",
    "guide_pass_code_verify": "验证码再次验证",
    "guide_verify_fail": "验证失败",
    "guide_click_verify": "申请验证",
    "guide_check_fail_reason": "查看失败原因",
    "pending_tips": "平台正在开通中，点击刷新按钮，可立即获取最新的信息。",
    "doing_warning": "请在5~10秒后再次尝试。",
    "guide_open_submit_success": "手机号码已输入",
    "guide_open_verify_success": "验证码已提交",
    "guide_click_send": "发送验证码",
    "guide_waiting_pass_code": "开通平台将在1-2个工作日内完成，请耐心等待",
    "guide_submit_phone_failed": "系统繁忙，请稍后再试。",
    "have_opened": "已开通",
    "guide_open_security": "{0}平台会检测注册行为的安全情况，需要等待一段时间。处理完成后，会邮件通知您进行下一步操作。",
    "guide_get_code_error": "系统接口异常！",
    "bilibili_tips_1": "哔哩哔哩平台被广泛称为 \"中国版 YouTube\"，在 18-35 岁的 MZ 一代中尤其受欢迎。💖",
    "bilibili_tips_2": "该平台的主要用户群是 24 岁以下的年轻人，占用户总数的 75%，月活跃用户约为 3.15 亿，活跃创作者约为 380 万。🏆",
    "bilibili_tips_3": "该平台支持横向和纵向视频格式，其独特的跨屏拍摄功能使其成为吸引年轻受众的数字内容平台。",
    "bilibili_tips_4": "- Global Pass 认证",
    "xiaohongshu_tips_1": "该平台被称为 \"中国版 Instagram\"，深受 18-34 岁女性的欢迎。💖它拥有 2.6 亿月活跃用户，约 2000 万活跃创作者。🏆",
    "xiaohongshu_tips_2": "它支持横向和纵向视频格式，并结合了社交网络和电子商务功能，用户可以文字和视频两种格式分享产品评论、游记等。小红书采用棋盘式布局，为年轻用户提供引人入胜的内容，是一款强大的病毒式营销工具。",
    "xiaohongshu_tips_3": "- Global Pass 认证",
    "kuaishou_tips_1": "快手通常被称为 \"中国版的TikTok\"，深受青少年（主要是18岁以下的青少年）的喜爱，月活跃用户达6亿。💖它拥有 200 多万创作者，主要支持垂直视频。🏆",
    "kuaishou_tips_2": "快手以其抒情、日常和农村内容而闻名，直播电商的销售比例也很高。最近，该平台的年轻用户群有所增加，内容上传频率非常重要。",
    "kuaishou_tips_3": "- Global Pass 认证",
    "iqiyi_tips_1": "爱奇艺 \"常被称为 \"中国版 Netflix\"，用户遍及各个年龄段。它拥有 5.4 亿月活跃用户和 300 多万创作者。💖它主要支持横屏格式的视频。🏆",
    "iqiyi_tips_2": "爱奇艺还制作了包括电视剧和综艺节目在内的各种全球内容，作为一个视频服务平台，其全球付费用户数量超过了 Netflix。",
    "iqiyi_tips_3": "- Global Pass 认证",
    "haokan_tips_1": "好看视频通常被称为 \"中国版的 Naver\"，主要面向 26-35 岁的用户。💖 该平台拥有约 1.1 亿月活跃用户和 200 多万活跃创作者。它主要支持横向格式的视频。🏆",
    "haokan_tips_2": "好看视频是中国最大的搜索引擎百度的旗舰中格式视频平台，深受政府影响，运营政策相对保守。",
    "haokan_tips_3": "- Global Pass 认证",
    "haokan_tips_4": "- 在中国平台上开设并验证了至少一个频道",
    "haokan_tips_5": "- 在 YouTube、TikTok 或中文频道上至少有一个以您的面孔为主题的视频（*对于订阅用户超过 100 万的频道，可能会在没有以您的面孔为主题的视频的情况下进行特别筛选）",
    "qqworld_tips_1": "QQ世界 \"平台通常被称为 \"中国版的Kakao Story\"，是中文版KakaoTalk（QQ）和KakaoStory的结合体，尤其受1995年后出生的年轻用户欢迎。💖 该平台拥有约3.5亿月活跃用户和120多万活跃创作者。QQ 支持横向和纵向视频格式，不提供基于浏览量的货币化服务。🏆",
    "qqworld_tips_2": "它的受众比较年轻，主要是学生，因此积极分享现代和多样化的内容。",
    "qqworld_tips_3": "- Global Pass 认证",
    "qqworld_tips_4": "- 在中国平台上开设并验证了至少一个平台",
    "xigua_tips_1": "新浪微博 \"平台被称为 \"中国版的 YouTube\"，45% 的用户年龄在 25-35 岁之间，男女比例相近。💖它拥有 1.8 亿月活跃用户，约 320 万活跃创作者。它主要支持横屏格式的视频。🏆",
    "xigua_tips_2": "凭借 Bytedance 的个性化内容推荐算法，Sigwa 拥有庞大的用户群和广泛的内容领域，尤其是 Vlog 和来自 \"\"3Nong\"\"（农业、农村、农民）领域的内容。 通过与 Dowin 平台的协同作用，它也有望实现快速增长。",
    "xigua_tips_3": "- Global Pass 认证",
    "xigua_tips_4": "- 在中国平台上开设并验证了至少一个平台",
    "bilibili_need_time": "预计时间：平均 1-2 个工作日",
    "xiaohongshu_need_time": "预计时间：平均 2-3 个工作日",
    "kuaishou_need_time": "预计时间：平均 2-3 个工作日",
    "iqiyi_need_time": "预计时间：平均 1-2 个工作日",
    "haokan_need_time": "预计时间：平均 10-15 个工作日",
    "qqworld_need_time": "预计时间：平均 5-10 个工作日",
    "xigua_need_time": "预计时间：平均 5-10 个工作日",
    "guide_waiting_open_title": "您的平台开通申请已经提交成功。🎊",
    "guide_waiting_open_subtitle": "Adobaro 将代表您处理所有复杂的平台账号创建和验证工作，确保正确、安全地完成。🔐",
    "guide_waiting_open_tip": "*开通和认证流程时间可能因您申请平台的运营情况而异。💖",
    "application_requirement": "⚠️申请条件",
    "guide_introduction": "平台介绍"
  },
  "auth": {
    "modal_title": "欢迎来到adobaro",
    "modal_subtitle": "Global Pass，保护您账号的最简单、最安全的方式！👇\n完成 Global Pass 验证后，我们将向您发放 Global Pass 验证卡，您可以使用该卡进行账号所有权验证、货币化、版权保护等操作。🎉",
    "modal_subtitle_tips": "在进行Global PASS认证之前，您可以通过试用版先体验adobaro。",
    "modal_plus_title": "需要Global Plus认证",
    "modal_plus_subtitle": "Global Plus认证后，可以获得",
    "modal_item1": "· 开通更多平台",
    "modal_item2": "· 开通平台收益",
    "modal_item3": "· 开通数据分析",
    "modal_item4": "· 开通功能",
    "modal_btn_next": "下次吧",
    "modal_btn_go": "去PASS认证",
    "modal_btn_trial": "去体验版",
    "modal_certify_title": "您听说过平台盈利的资格要求吗？",
    "modal_certify_column_1": "资格要求",
    "modal_certify_column_2": "Y公司",
    "modal_certify_column_3": "adobaro",
    "modal_certify_require_1": "订阅者",
    "modal_certify_require_2": "观看时长",
    "modal_certify_require_3": "观看次数",
    "modal_certify_company_1": "1000人",
    "modal_certify_company_2": "4,000小时",
    "modal_certify_company_3": "1,000万次",
    "modal_certify_ro_1": "0",
    "modal_certify_ro_2": "0",
    "modal_certify_ro_3": "0",
    "modal_certify_explication_1": "大约需要7个月时间才能达到Y公司的平均盈利？\nadobaro与全球通行证认证同时开通盈利功能。",
    "modal_certify_explication_2": "还有其他吗？\n扩展可开通的平台\n升级到每周上传2次\n增加文件存储容量等等！",
    "modal_certify_explication_3": "立即开始享受全球通行证认证的优惠吧",
    "modal_certify_btn": "认证后开始盈利！",
    "auth_center": "Global PASS",
    "auth_center_subtitle": "管理您的认证信息",
    "auth_global": "Global PASS",
    "auth_creator": "Global Plus PASS",
    "auth_history": "使用历史",
    "auth_global_subtitle": "为保证收益以及账号安全提升",
    "auth_creator_subtitle": "为条件满足的创作者，提供更多的曝光以及机会",
    "auth_history_subtitle": "可以查看认证使用记录",
    "auth_global_title": "Global认证",
    "auth_creator_title": "Global Plus认证",
    "auth_history_title": "PASS 通行证使用记录",
    "creator_auth_tip": "请在Global认证完成后,再提交Global Plus认证",
    "pass_title": "已认证",
    "passport_title": "护照信息",
    "passport_tip": "为了接收特定于平台的结算，您必须验证您的 Global PASS。\n请提交认证所需文件如下。",
    "platform_title": "平台信息",
    "platform_name": "平台名称",
    "platform_url": "平台链接",
    "passport_dashboard_title": "签名的护照扫描件",
    "passport_dashboard_tip": "上传文件前请确保已签名",
    "passport_handhold_title": "已签名的护照和一张自拍照",
    "passport_handhold_tip": "您必须清晰地拍摄签名的护照",
    "platform_dashboard_title": "个人主页截图",
    "platform_handhold_title": "平台后台与本人合照",
    "btn_submit_picture": "同意协议并提交认证",
    "protocol_tip": "请阅读 {0}，申请认证并同意协议",
    "protocol_platform": "adobaro委托协议书",
    "passport_question": "为什么需要我的护照？",
    "passport_answer": "像出国旅游时随身携带护照一样，护照也是您在境外平台操作时用来验证身份的。\n注册中文平台需要实名认证，您的个人信息通过adoba Global PASS认证系统受到保护。\n通过Global PASS认证，无需单独筛选，即可授予各个平台的上传权，并通过各个平台上的活动产生收入。",
    "passport_income_comparison": "Y公司收益条件对比adobaro条件",
    "passport_pass_success": "adobaro Global PASS 认证后获得收益化资格",
    "pass_no": "认证号",
    "pass_duration": "认证期限",
    "pass_explain": "为保护您的隐私，不会显示为{0}的护照信息。",
    "pass_in_review": "您的{0}认证申请已提交,等待审核中，处理时间工作日3-5日，请稍等",
    "pass_in_fail": "您的{0}申请审核失败,失败原因: {1}",
    "pass_expire_warning": "您的{0}即将在{1}过期,请尽快重新提交认证",
    "pass_expire_already": "您的{0}认证已过期,请重新提交认证",
    "pass_submit_success_tip": "您的{0}认证已提交，审核时间工作日3-5日，审核中可以申请开通平台",
    "pass_submit_fail_tip": "您的{0}认证提交失败",
    "auth_attention": "认证注意事项",
    "global_attention_question_1": "1、为什么需要护照？",
    "global_attention_answer_1": "为保证平台收益以及账号安全提升",
    "global_attention_question_2": "1、为什么需要护照？",
    "global_attention_answer_2": "为保证平台收益以及账号安全提升",
    "global_attention_question_3": "1、为什么需要护照？",
    "global_attention_answer_3": "为保证平台收益以及账号安全提升",
    "global_attention_question_4": "1、为什么需要护照？",
    "global_attention_answer_4": "为保证平台收益以及账号安全提升",
    "global_attention_pass_question_1": "1、认证通过后，怎么取消global认证？",
    "global_attention_pass_answer_1": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "global_attention_pass_question_2": "2、认证通过后，怎么取消global认证？",
    "global_attention_pass_answer_2": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "creator_attention_question_1": "1、为什么需要护照？",
    "creator_attention_answer_1": "为保证平台收益以及账号安全提升",
    "creator_attention_question_2": "1、为什么需要护照？",
    "creator_attention_answer_2": "为保证平台收益以及账号安全提升",
    "creator_attention_question_3": "1、为什么需要护照？",
    "creator_attention_answer_3": "为保证平台收益以及账号安全提升",
    "creator_attention_question_4": "1、为什么需要护照？",
    "creator_attention_answer_4": "为保证平台收益以及账号安全提升",
    "creator_attention_pass_question_1": "1、认证通过后，怎么取消Global Plus认证？",
    "creator_attention_pass_answer_1": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "creator_attention_pass_question_2": "2、认证通过后，怎么取消global认证？",
    "creator_attention_pass_answer_2": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "attention_know_more": "了解更多",
    "cancel_auth": "取消认证",
    "cancel_auth_title": "您确定要取消认证吗？",
    "cancel_auth_subtitle": "取消认证后所有账号收益立即停止，同时注销平台账号，确认吗？",
    "cancel_auth_success": "取消认证成功",
    "cancel_auth_fail": "取消认证失败",
    "attention_approve": "对答案满意吗？",
    "history_country": "国家",
    "history_platform": "平台",
    "history_auth_type": "认证",
    "history_use_todo": "用处",
    "history_timestamp": "时间",
    "use_type_handling": "搬运处理",
    "use_type_open_income": "开通收益",
    "use_type_open_platform": "平台开通",
    "upload_global_pass": "Global Pass 认证",
    "upload_cancel": "取消",
    "upload_confirm": "确认",
    "global_pass_failed": "护照验证失败",
    "global_pass_failed_des": "根据平台反馈，认证失败。 失败原因：{0}。 请检查您的信息并重新上传",
    "upload_btn": "上传信息",
    "account_create_success": "Global Pass 信息授权",
    "allow_global_pass": "我同意在平台开通和验证过程中使用我的全球通行证信息。",
    "allow_and_accredit": "同意",
    "upload_global_pass_subtitle": "提交您名下的护照以进行全球通行证验证。",
    "example_pic_tip": "示例图",
    "back_trial_version": "关闭",
    "upload_pass_more": "护照信息已上传，请勿重复操作!",
    "upload_pass_tips": "*提交的材料仅用于 Global Pass 认证目的，所有个人信息将受到严格保护。"
  },
  "upload": {
    "file_btn_status_removed": "上传文件",
    "file_btn_status_uploading": "上传中",
    "file_btn_status_done": "更换文件",
    "file_btn_status_error": "重新上传",
    "file_dragger_subtitle": "拖拽文件到此处也可上传",
    "file_dragger_tip": "支持文件：{0}",
    "file_status_uploading": "已上传:",
    "file_status_done": "已完成",
    "file_status_error": "上传失败",
    "file_type_error": "不支持该文件格式。",
    "file_limit_size": "文件需小于{0}",
    "file_limit_size_tip": "(小于{0})",
    "file_limit_min_size": "(*{0} 或以下)",
    "file_change_tip": "更换文件",
    "file_upload_image_tip": "上传图片",
    "file_change_image_tip": "更换图片",
    "file_name_already_exist": "文件名已存在于文件存储中。请更改文件名并重新上传",
    "file_size_exceeds_capacity": "您的素材管理存储空间不足,请检查存储空间",
    "header_title": "上传视频",
    "header_subtitle": "(✿◡‿◡) 当您上传内容时，将针对每个平台自动生成标题和缩略图。✌️",
    "radio_quick": "快速上传视频（Youtube链接）",
    "radio_local": "本地上传视频",
    "label_video_link": "视频地址",
    "video_link_holder": "请输入youtube视频链接地址",
    "video_youtube_link_invalid_msg": "Youtube链接无效！",
    "video_youtube_title_msg": "无法获取视频标题，请稍后重试！",
    "upload_file_holder": "请上传文件",
    "upload_file_complete_tip": "请等待文件上传完成后再点击投稿",
    "select_platform": "选择平台",
    "select_platform_holder": "请选择要上传的平台",
    "open_more_platform": "开通新平台",
    "basic_info": "内容信息",
    "basic_info_tip": "您只需填写基本信息，adoba.AI操作剩下信息",
    "basic_content_title": "内容标题",
    "basic_content_title_holder": "请输入内容标题。您输入的内容将针对平台自动翻译为适合的中文并上传。",
    "basic_content_cover": "内容封面",
    "basic_content_cover_holder": "请上传内容封面",
    "basic_content_cover_upload": "支持文件：{0}",
    "basic_content_cover_upload_tip": "⭐AI缩略图功能会自动为每个平台生成并上传最优化的缩略图。\n🚨为了使用AI缩略图功能，请务必上传缩略图图像中不包含文字的清洁版。",
    "basic_content_cover_change_tip": "更换封面",
    "basic_content_intro": "内容简介",
    "basic_content_intro_holder": "请输入内容介绍，您输入的内容将自动翻译为针对平台优化的中文并上传。",
    "btn_release": "AI 智能上传",
    "release_content_success": "您的上传请求已完成。🎉\n我们将通过通知和电子邮件通知您上传进度结果。\n💡 请注意，审查内容是否符合每个平台的规定，包括检查非法和成人材料。根据这些规定，您的上传请求可能会被拒绝。",
    "release_content_fail": "投稿失败",
    "release_count": "剩余{0}次",
    "platform_frozen": "已冻结",
    "platform_unbound": "未认证",
    "publish_count_out": "您的投稿次数已使用完毕,暂时不能进行投稿",
    "release_upload_tips": "⭐通过AI智能上传功能，分析内容和各平台的主要活跃时间，以便在最佳时间自动上传。\n🚨如果需要身份验证，可能需要验证码确认程序。",
    "release_time_tips": "⚠️上传次数将在当地时间{0}（UTC00:00）更新。"
  },
  "wallet": {
    "income_title": "收益",
    "income_overview": "收入概览",
    "income_platform": "按平台划分的收入",
    "income_member": "成员收入",
    "income_total": "累计总收入",
    "income_total_tip": "到目前为止获得的总收益",
    "withdraw_total": "累计提现金额",
    "withdraw_total_tip": "到目前为止结算的总金额",
    "revenue_balance": "钱包余额",
    "revenue_balance_tip": "未结算总收益的余额",
    "account_info": "账户信息",
    "apply_withdraw": "申请提现",
    "settlement_detail": "结算详情",
    "detail_date": "提现日期",
    "detail_total": "收益合计(含税)",
    "detail_tax": "扣税",
    "detail_service_charge": "手续费",
    "detail_amount": "打款金额",
    "detail_status": "打款状态",
    "detail_action": "操作",
    "detail_status_ongoing": "处理中",
    "detail_status_success": "提现成功",
    "detail_status_failure": "提现失败",
    "withdrawable_detail_btn": "查看详情",
    "withdrawal_directions1": "⁕ 钱包余额超过[10000]元的，可以申请收入提现。",
    "withdrawal_directions2": "⁕ 申请提现时，转账将于下个月月底前完成。（由于国际汇款，存款时间可能会有所不同。）",
    "withdrawal_directions3": "⁕ 结算服务基本佣金率：25%",
    "withdrawal_directions4": "⁕ 钱包余额为平台提供的数字总和，显示金额可能因结算时间而异。",
    "withdrawal_directions5": "⁕ 收益结算时货币收益根据汇率而变化",
    "detail_modal_detail": "提现明细",
    "detail_modal_detail_table": "结算明细表",
    "detail_modal_period": "结算周期",
    "detail_modal_amount": "平台收益",
    "detail_modal_service_fee": "手续费",
    "detail_modal_tax": "扣税",
    "detail_modal_settlement_amount": "税后金额",
    "detail_drawer_money_rate": "打款汇率",
    "detail_drawer_money_krw": "打款韩币",
    "detail_drawer_tip": "打款韩币已经扣个人所得税3.3%",
    "account_paypal_tips": "Paypal可以提供安全便捷的服务，支持多种货币交易，满足不同国家和地区的用户需求。请填写您的Paypal邮箱账户，完成账户信息设置。您可以登录Paypal官方网站（{0}）进行注册或信息查询。",
    "account_paypal": "Paypal",
    "account_paypal_error": "Paypal账户为邮箱格式",
    "bank_not_set": "未设置",
    "account_info_change_header": "修改账户信息",
    "account_email_code": "邮箱验证码",
    "account_email_code_holder": "请输入邮箱验证码",
    "account_email_code_error": "验证码错误或已失效!",
    "account_paypal_holder": "请输入Paypal账户",
    "account_update_success": "更新账户信息成功!",
    "account_update_fail": "更新账户信息失败!",
    "monetary_unit_cny": "元",
    "monetary_unit_dollar": "美元",
    "monetary_unit_krw": "韩币",
    "income_month": "月收入",
    "all_platform": "全部平台",
    "platform_income_detail": "平台收入详情",
    "platform_income_title": "平台",
    "platform_income_total": "平台收益(元)",
    "platform_income_daily": "每日收益",
    "platform_total": "月收入(元)",
    "member_top_10": "收益TOP10",
    "member_income_detail": "成员收入详情",
    "member_name": "成员名称",
    "member_chart_total": "累计收入(元)",
    "member_income_total": "成员收益(元)",
    "platform_drawer_tips_wait": "各平台数据收集时间不同，请耐心等待",
    "platform_drawer_tips_unit": " 收益金额是人民币",
    "platform_drawer_tips_account": "日期收益以MCN账户到账为准",
    "platform_drawer_tips_date": "仅显示近36月收益数据",
    "platform_drawer_title": "{0}收入详情",
    "platform_drawer_date": "日期",
    "platform_drawer_plan": "计划",
    "platform_drawer_income": "平台收益",
    "platform_drawer_tax": "税费",
    "platform_drawer_service_fee": "手续费",
    "platform_drawer_total": "汇总",
    "platform_drawer_settlement_amount": "结算金额",
    "confirm_header": "确认信息",
    "detail_modal_bank_btn": "填写银行信息",
    "detail_modal_to_bank": "填写银行信息后，才能申请提现",
    "detail_modal_tips": "⁕ 按照税法，需要扣所得税3.3%",
    "detail_modal_apply_btn": "确认提现",
    "platform_drawer_collecting": "数据收集中",
    "platform_drawer_show_more": "展开更多",
    "platform_drawer_show_close": "收起",
    "member_income_detail_name": "账号名",
    "withdrawable_apply_disable": "当前不满足提现条件，无法进行提现操作",
    "bank_verify_modal_save": "验证",
    "modify_phone_verify_head": "身份验证",
    "modify_phone_not_received": "没收到验证码？",
    "bank_verify_content": "为了你的账户安全，请验证身份。我们已经向您的邮箱发送验证码，请在下方输入验证码，完成验证。",
    "bank_verify_code_holder": "请输入验证码",
    "modal_success_title": "提现申请成功",
    "modal_failure_title": "系统有误，请稍后申请",
    "modal_close_remind": "此弹窗将在{0}秒后自动关闭",
    "account_setting": "设置/修改",
    "bank_update_fail": "更新银行身份信息失败!",
    "export_data": "导出数据",
    "detail_modal_tips_bank": "· 账户信息不正确的话，申请提现会失败",
    "detail_modal_tips_tax": "· 按照税法，需要扣所得税3.3%",
    "member_incomes_total": "累计",
    "overview_withdrawable": "可提现金额",
    "withdrawal_paypal_not_fount": "您还未设置Paypal账户，是否前往设置？",
    "detail_to_account_date": "预计{0}到账"
  },
  "setting": {
    "title": "设置",
    "subtitle": "进行adobaro相关设置",
    "enterprise_info": "企业信息",
    "account_security": "账号安全",
    "notification": "通知",
    "protocol": "协议",
    "user_uid": "UID",
    "user_name": "企业名称",
    "user_email": "邮箱",
    "user_password": "密码",
    "user_third_party_login": "第三方登录",
    "user_not_set": "未设置",
    "user_set_password": "修改密码",
    "user_to_change": "变更",
    "user_bound": "已绑定",
    "user_to_bound": "绑定",
    "user_bound_google": "已绑定谷歌账号",
    "user_unbound_google": "未绑定谷歌账号",
    "message_google_bound": "此邮箱已被注册，绑定失败！",
    "user_phone_title_bind": "请输入已绑定手机号",
    "user_phone_subtitle_bind": "为了你的账户安全，请验证身份。验证成功后进行下一步操作。",
    "user_phone_title_new": "请输入新手机号",
    "user_login_country_check": "请选择国家！",
    "user_modify_phone_verify_fail": "手机号验证失败！",
    "user_support_email": "客服邮箱",
    "user_code_not_received_tip": "如果您未收到验证码,请通过{0}进行处理。",
    "user_code_not_received": "没收到验证码？",
    "user_verify_header": "身份验证",
    "user_modify_phone_header": "更改手机号",
    "user_modify_email_update_button": "更新邮箱",
    "user_modify_email_explanation": "为了你的账户安全，请先输入当前邮箱进行验证。",
    "user_modify_email_old_holder": "请输入当前邮箱",
    "user_modify_email_old_popover": "如果您不记得您加入的电子邮件地址,请通过{0}进行咨询。",
    "user_modify_email_forgot": "忘记邮箱？",
    "user_modify_email_verify_fail": "邮箱验证失败！",
    "user_modify_new_email": "新邮箱",
    "user_modify_email_new_holder": "请输入新邮箱",
    "user_modify_email_title": "更改邮箱",
    "user_modify_password_change_button": "更新密码",
    "user_modify_password_title": "更改密码",
    "user_modify_password_explanation": "为了你的账户安全，请先输入邮箱进行验证",
    "user_modify_new_password": "新密码",
    "user_modify_password_new_holder": "请输入新密码",
    "user_modify_password_confirm_holder": "请再次输入密码",
    "user_modify_password_head": "更新密码",
    "security_signin": "登录安全",
    "security_credit": "信用分",
    "security_signin_date": "登录时间",
    "security_signin_mode": "登录方式",
    "security_signin_third": "第三方登录",
    "security_signin_google": "谷歌授权登录",
    "security_signin_account": "账号登录",
    "security_signin_ip": "IP地址",
    "security_signin_country": "国家",
    "security_signin_action": "操作",
    "delete_confirm_title": "确认删除吗？",
    "credit_action_date": "操作时间",
    "credit_action_behavior": "行为",
    "credit_ score": "信用分",
    "protocol_user": "adobaro用户协议",
    "protocol_privacy": "adobaro隐私政策",
    "protocol_marketing": "adobaro营销通知书",
    "notification_history": "通知历史",
    "notice_date": "日期",
    "notice_title": "通知标题",
    "notice_detail": "详细内容",
    "delete_all_btn": "全部删除",
    "notice_delete_all": "您确定要全部删除吗？",
    "notice_delete_all_subtitle": "删除全部的后果删除全部的后果删除全部的后果",
    "notice_details": "通知详情",
    "notice_read_all": "全标为已读",
    "notice_read_all_confirm": "确认全部标记为已读吗？",
    "cancellation_apply": "解约申请",
    "cancellation_record": "解约记录",
    "cancellation_apply_initiate": "发起解约",
    "cancellation_apply_time": "申请时间",
    "cancellation_account_name": "账户名称",
    "cancellation_apply_side": "发起解约",
    "cancellation_status": "解约状态",
    "cancellation_action": "操作",
    "cancellation_apply_side_creator": "达人",
    "cancellation_apply_side_mcn": "企业",
    "cancellation_status_waiting": "待解约",
    "cancellation_status_reject": "已拒绝",
    "cancellation_status_cancel": "已取消",
    "cancellation_status_success": "已解约",
    "cancellation_action_reject": "拒绝",
    "cancellation_action_agree": "同意",
    "cancellation_action_cancel": "取消",
    "cancellation_action_resend": "重新发起",
    "cancellation_reject_title": "是否拒绝解约申请?",
    "cancellation_agree_title": "是否同意解约申请?",
    "cancellation_cancel_title": "是否取消解约申请?",
    "cancellation_resend_title": "是否重新发送解约申请?",
    "cancellation_member": "我的成员",
    "cancellation_member_placeholder": "请输入成员完整的UID",
    "cancellation_member_btn": "解约",
    "cancellation_record_start_time": "签约时间",
    "cancellation_record_end_time": "解约时间",
    "cancellation_record_income": "收益",
    "cancellation_record_check_title": "暂时无法查看该用户"
  },
  "userAgreement": {
    "title": "adobaro用户协议",
    "updated": "更新时间：2023年【10】月【31】日",
    "effective": "生效时间：2023年【10】月【31】日",
    "introduction": "【首部及导言】",
    "introduction_1": "《adobaro用户使用协议》（以下简称“本协议”）是您与adobaro之间关于使用adobaro各项服务的法律协议。“adobaro”是指adobaro的运营公司安哆巴文化传媒（四川）有限公司及其关联公司（以下简称“公司”）。",
    "introduction_2": "本协议中与您的权益（可能）存在重大关系的条款（包括免除adobaro责任的条款、限制您权利的条款、争议解决条款及司法管辖条款等），adobaro已采用字体加粗的方式来特别提醒您，请您留意重点查阅。请您确保您在使用adobaro服务前已仔细阅读、充分理解并毫无保留地接受本协议所有条款。",
    "introduction_3": "《adobaro隐私政策》、《帮助中心》以及adobaro公布的其他 协议规范、规则、声明、公告、政策等（以下统称“其他adobaro规则”）是本协议不可分割的组成部分，您在使用adobaro服务时应同样遵守。",
    "introduction_3_bold": "您接受并同意本协议的行为即视为您同意并接受其他adobaro规则，您接受并同意任一其他adobaro规则的行为亦视为您接受并同意本协议。",
    "introduction_4": "您确认您具备完全民事权利能力和完全民事行为能力，有能力同意并遵守本协议，并对您本协议项下的全部行为独立承担法律责任。若您不具备前述与您行为相适应的民事行为能力，则应获得法定监护人的知情同意。如您尚未成年，请在法定监护人的陪同下阅读和判断是否同意本协议，请特别注意未成年人条款。未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。",
    "content_1": "服务说明",
    "content_1_1": "adobaro向您提供包括但不限于如下服务：",
    "content_1_1_1": "adobaro网站：www.adobaro.com（及其他由adobaro运营的任何网站，或依托于签署网站或软件关联网站或软件）；",
    "content_1_1_2": "adobaro直接运营或与第三方合作运营的包括但不限于PC、平板、手机、电视、机顶盒、可穿戴设备、车内平板设备、智能音箱设备等全部终端客户端产品；",
    "content_1_1_3": "adobaro用户素材管理、内容管理、平台管理；",
    "content_1_1_4": "adobaro直接拥有或运营的服务器、网络存储空间；",
    "content_1_1_5": "adobaro提供给您的各类增值服务；",
    "content_1_1_6": "adobaro提供给您的其他技术和/或服务，具体以adobaro实际提供的为准，adobaro有权自行决定增加、关停或其他调整。",
    "content_1_2": "adobaro所提供的服务，均限于在上述1.1.1及1.1.2所述平台（以下简称“adobaro相关平台”）内使用，任何以恶意破解等非法手段将adobaro所提供的服务与adobaro相关平台分离的行为，皆不属于本协议约定的由adobaro提供的服务。由此引起的一切后果由行为人负责，adobaro将保留依法追究行为人法律责任的权利。adobaro所提供的服务仅供用户非商业性使用，用户在未经adobaro事先书面同意的情况下，不得以商业性目的使用adobaro所提供的服务。",
    "content_1_3_bold": "adobaro官方所公布的方式为注册、登录、下载客户端（包括但不限于iOS、Android等智能平台）、使用adobaro服务的唯一合法方式，用户通过其他任何途径、任何渠道、任何方式获取的adobaro服务（包括但不限于账号、客户端下载等）均为非法所得，adobaro概不承认其效力，且一经发现，adobaro有权立即作出删除、清零、封号等处理，任何因此导致的一切不利后果均由用户自行承担。",
    "content_1_3": "请用户妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，adobaro不承担补偿责任。",
    "content_1_4": "adobaro有权提前向用户公告（包括但不限于弹出公告、网站首页公告、系统消息）以修改、替换、升级与adobaro服务相关的任何软件。",
    "content_1_4_bold": "如果用户不同意或者不接受adobaro相关软件的修改、替代、升级，请直接拒绝、停止、取消使用行为，否则视为用户同意并接受adobaro相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。",
    "content_1_5": "本协议所称“用户”，包括注册获得adobaro账号的用户（以下简称“注册用户”）及未注册获得adobaro账号的用户（以下简称“非注册用户”）。凡未注册adobaro的产品和/或服务的用户，自下载或安装adobaro的产品和/或使用adobaro的服务时即自动成为adobaro的“非注册用户”。",
    "content_1_6": "特别提醒：由于adobaro的产品和服务较多，为您提供的产品和服务内容也有所不同，本协议为adobaro统一适用的一般性用户服务条款。针对adobaro的某些特定产品/服务，adobaro还将制定特定用户服务协议，以便更具体地与您约定adobaro的服务内容、服务规则等内容，您应在充分阅读并同意特定用户服务协议的全部内容后再使用该特定产品/服务。",
    "content_2": "注册账号",
    "content_2_1": "部分adobaro服务仅向注册用户提供，如果您使用adobaro提供的网络存储空间进行视听节目、文字、美术摄影等内容的上传及传播等，请先根据本协议及其他adobaro规则提示的规则、流程注册成为注册用户，并确保注册信息的真实性、正确性及完整性，如果上述注册信息发生变化，您应及时更改。",
    "content_2_2": "您应对注册获得的adobaro账号（以下简称“账号”）项下的一切行为承担全部责任，应遵守中华人民共和国（以下简称：中国或国家）的法律法规，社会道德风尚和信息真实性等原则，不得出现不良信息，不得冒用他人姓名、名称、头像、字号或采取其他足以引起混淆的方式设置账号，不得侵犯包括但不限于adobaro在内的任何主体的合法权益。",
    "content_2_3": "您理解并同意，您仅享有账号及账号项下由adobaro提供的虚拟产品及服务的使用权，账号及该等虚拟产品及服务的所有权归adobaro所有（法律法规另有规定的除外）。未经adobaro书面同意，您不得以任何形式处置账号的使用权（包括但不限于赠与、出借、转让、销售、抵押、继承、许可他人使用）。",
    "content_2_3_bold": "如果adobaro发现或者有合理理由认为使用者并非账号初始注册人，adobaro有权在不通知您的情况下，暂停或终止向该注册账号提供服务，并注销该账号，由此产生的内容或虚拟财产的情况等损失由您本人或账号使用者承担。",
    "content_2_4": "您应妥善保管账号信息、账号密码以及其他与账号相关的信息、资料。如因您的原因，造成账号信息、资料、数据的变动、灭失或财产损失等，您应立即通知adobaro并自行承担相关法律后果。",
    "content_2_5": "若您通过第三方网站或服务使用adobaro账号登录的，除非adobaro特别说明，这些网站或服务并非adobaro运营，您应自行判断第三方网站的安全性和可用性，并自行承担相关的风险和责任。",
    "content_2_6": "您同意并承诺做到：",
    "content_2_6_1": "当您的账号或密码遭到未经授权的使用，或者发生任何安全问题时，您会立即有效地通知到adobaro；",
    "content_2_6_2": "当您每次登录adobaro或使用相关服务后，会将有关账号等安全退出；",
    "content_2_6_3": "您同意接受adobaro通过电子邮件、客户端、网页或其他合法方式向您发送通知信息和其他相关信息。",
    "content_2_7": "您同意并承诺不从事以下行为：",
    "content_2_7_1": "冒用他人信息为自己注册adobaro账号；",
    "content_2_7_2": "未经他人合法授权以他人名义注册adobaro账号；",
    "content_2_7_3": "使用同一身份认证信息注册多个adobaro账号（包括经adobaro审核认定多个adobaro账号的实际控制人为同一人的情形）；",
    "content_2_7_4": "窃取、盗用他人的adobaro账号、会员标识等；",
    "content_2_7_5": "使用侮辱、诽谤、色情、政治等违反中华人民共和国法律、道德及公序良俗的词语注册adobaro账号；",
    "content_2_7_6": "以非法占有adobaro相关服务资源为目的，通过正当或非正当手段恶意利用网站漏洞；",
    "content_2_7_7": "侵犯他人合法权益的其他内容。",
    "content_2_8": "您理解并同意，adobaro有权对违反上述条款的用户作出禁止注册及/或封号的处理。",
    "content_2_9": "鉴于adobaro提供的服务包括通过adobaro开通中国第三方互联网运营平台（以下简称：中国平台或平台），您需要特别注意：",
    "content_2_9_1": "为保持登录账号与运营平台账号的归属一致，您必须使用您本人的手机号作为开通平台的账号；",
    "content_2_9_2": "adobaro仅支持在adobaro中已经上架的平台进行申请开通，未上架在adobaro中的平台,即表示adobaro当前不支持且不承担运营的相关责任；",
    "content_2_9_3": "在adobaro中开通平台需要您本人进行申请操作，需要您自行按照申请方式申请，且adobaro将该申请行为视为本人操作。您申请后，视为同意adobaro使用您的手机号进行平台申请注册；",
    "content_2_9_4": "中国平台会不定时不定期变更其运营规则，adobaro也会在获取变更信息后及时将新规则通过通知的方式告知到您，请注意adobaro中的通知信息，避免因平台运营规则变更而造成损失；",
    "content_2_9_5": "开通中国平台前，请确保您的手机账号可以长久使用。如果您的手机号已经在adobaro中成功开通平台，但您的手机号已经不使用或无法找回，导致无法登陆平台的，或长时间未登陆等原因导致对应平台的账号将作废，造成的损失由您本人负责（包括平台账号损失、虚拟财产损失）；若您使用全新的手机号申请开通平台，该手机号对应的账号将是一个全新的账号，与上一个手机号互不冲突或交叉。",
    "content_2_10": "您了解并知悉，您可以使用账号登录adobaro提供的adobaro体系下全部产品。",
    "content_2_11": "您理解并同意，adobaro有权在法律允许的最大范围内视情况决定收回账号使用权，无需另行通知用户亦无需征得用户同意。",
    "content_2_12": "你同意你在本网站的帐号及相应帐号所发布的全部内容均授权adobaro以你的帐号自动同步发布至公司及 / 或关联公司及 / 或控制公司运营的其他软件及网站，公司及 / 或关联公司及 / 或控制公司有权以合适的形态在其他软件及网站上展现。你在adobaro发布、修改、删除内容的操作，均会同步到上述系列其他软件及网站。 你通过已注册帐号或者第三方软件用户帐号注册或登录公司及 / 或关联公司及 / 或控制公司运营的其他软件产品及网站时（如有），应遵守该软件及网站自身的《用户协议》及其他协议条款的规定。为进一步避免疑义，你确认并同意，你在本网站的帐号及帐号内发布的内容将全部或部分同步至公司提供内容服务的、申请开通过平台的客户端应用程序和网站。该授权在授权期间内是免费的、不可撤销的、独占的、无地域限制的许可。",
    "content_2_13": "你理解并同意，为了充分使用帐号资源，adobaro按照每个平台的规则来进行运营。",
    "content_3": "adobaro上的内容",
    "content_3_1": "adobaro上的内容是指经由adobaro提供的服务，以上传、张贴或任何其他方式传送或传播的任何资讯、资料、文字、软件、音乐、音频、照片、图形、视频、信息、链接或其他资料，无论系公开还是私下传送（以下简称“内容”），内容提供者、上传者应对其提供、上传的内容承担全部责任，",
    "content_3_1_bold": "如果给adobaro造成损失的，还应向adobaro承担赔偿责任。对于第三方因用户上传的内容向adobaro主张权利的，adobaro有权在不事先通知内容提供者、上传者的情况下直接采取删除、屏蔽、断开链接等必要措施。",
    "content_3_2": "您在adobaro上传或发布的内容，您保证对其享有合法的著作权或相应授权，adobaro有权展示、散布及推广前述内容。",
    "content_3_3": "adobaro将额外为您提供全球范围内的展示和推广服务，您同意adobaro将您账号中发布的全部内容展示于adobaro直接运营或与第三方合作运营的产品，包括但不限于PC、平板、手机、电视、机顶盒、可穿戴设备、车内平板设备、智能音箱设备等全部客户端软件和/或网站。同时，您允许adobaro在展示内容时可自行或委托第三方进行必要的处理（包括但不限于翻译、汇编、改编等）。您在此确认并同意，adobaro有权自行或委托第三方在与上述内容、adobaro产品及相关服务、adobaro和/或adobaro品牌有关的任何宣传、推广和/或研究中以适当的方式开发和使用上述内容（全部或部分）。为避免疑义，您理解并同意，上述内容包括您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记等。",
    "content_3_4": "adobaro无法预先知晓并合理控制经由adobaro服务上传之内容，因此，您已预知在使用adobaro的服务时，可能会接触到部分令您感到不快、不适或厌恶的内容，您同意放弃由此产生的针对adobaro的任何追索权。但adobaro有权依法停止传输任何前述内容并采取相应处理，包括但不限于暂停您继续使用adobaro的部分或全部服务，保存有关记录并向有关机关报告。",
    "content_4": "使用规则",
    "content_4_1": "用户在使用adobaro服务的过程中，应遵守以下法律法规：",
    "content_4_1_1": "《中华人民共和国保守国家秘密法》；",
    "content_4_1_2": "《中华人民共和国著作权法》；",
    "content_4_1_3": "《中华人民共和国计算机信息系统安全保护条例》；",
    "content_4_1_4": "《计算机软件保护条例》；",
    "content_4_1_5": "《网络信息内容生态治理规定》",
    "content_4_1_6": "《信息网络传播权保护条例》",
    "content_4_1_7": "《中华人民共和国网络安全法》；",
    "content_4_1_8": "其他法律、法规、规章、条例等具有法律效力的规范。",
    "content_4_2": "禁止用户从事以下行为：",
    "content_4_2_1": "制作、上传、复制、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；",
    "content_4_2_2": "以任何方式危害未成年人；",
    "content_4_2_3": "冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；",
    "content_4_2_4": "伪造标题或以其他方式操控识别资料，使人误认为该内容为adobaro所传送；",
    "content_4_2_5": "将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送；",
    "content_4_2_6": "将侵犯任何人的专利、商标、著作权、商业秘密等知识产权的内容加以上载、张贴、发送电子邮件或以其他方式传送；",
    "content_4_2_7": "将广告函件、促销资料、\"垃圾邮件\"等，加以上载、张贴、发送电子邮件或以其他方式传送；",
    "content_4_2_8": "跟踪或以其他方式骚扰他人；",
    "content_4_2_9": "将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；",
    "content_4_2_10": "干扰或破坏adobaro服务或与adobaro服务相连的服务器和网络，或不遵守本协议之规定；",
    "content_4_2_11": "未经adobaro事先明确书面许可，以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）和任何理由自行或委托他人、协助他人获取平台的服务、内容、数据；",
    "content_4_2_12": "用户需保证其上传内容不得违反广电总局的相关规定，包括但不限于《互联网视听节目服务管理规定》（广电总局56号令）等，其上传节目应当符合法律、行政法规、部门规章的规定，上传内容不得含有以下内容：",
    "content_4_2_12_1": "反对宪法确定的基本原则的；",
    "content_4_2_12_2": "危害国家统一、主权和领土完整的；",
    "content_4_2_12_3": "泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；",
    "content_4_2_12_4": "煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；",
    "content_4_2_12_5": "宣扬邪教、迷信的；",
    "content_4_2_12_6": "扰乱社会秩序，破坏社会稳定的；",
    "content_4_2_12_7": "诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；",
    "content_4_2_12_8": "侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；",
    "content_4_2_12_9": "危害社会公德，损害民族优秀文化传统的；",
    "content_4_2_12_10": "有关法律、行政法规和国家规定禁止的其他内容。",
    "content_4_2_13_bold": "不得利用基于深度学习、虚拟现实等的新技术新应用制作、发布、传播虚假新闻信息等法律法规禁止的信息内容。您在发布或传播利用基于深度学习、虚拟现实等的新技术新应用制作的非真实音视频信息时，应当以显著方式予以标识，否则adobaro有权对相关内容和账户采取包括但不限于增加标识、限制、封禁等措施。",
    "content_4_2_13": "您制作、发布、传播的内容需遵守《网络音视频信息服务管理规定》等法律法规规定以及adobaro公布的创作规则，",
    "content_4_2_14": "如用户提供的上传内容含有以上违反政策法律法规的信息或者内容的，由用户直接承担以上导致的一切不利后果，如因此给adobaro造成不利后果的，用户应负责消除影响，并且赔偿因此导致的一切损失。",
    "content_4_3": "用户不得通过任何渠道或媒体（包括但不限于自媒体等）发出“与adobaro合作”、“与adobaro共同出品”等任何携带“adobaro”品牌的字样，如用户需宣传推广合作节目，用户只能在宣传中提及节目本身而不得提及与adobaro关系或者擅自以adobaro品牌进行推广，凡是用户的发稿带有“adobaro”的一切宣传稿件必须通过adobaro相应合作部门之书面同意，否则因此给adobaro造成的一切损失用户应予以赔偿。",
    "content_4_4": "付费套餐使用规则",
    "content_4_4_1": "用户在adobaro.com订购付费套餐时，已阅读并理解adobaro用户协议，并同意用户可以根据用户选择的付费套餐的计费方式自动按月或按年计费。",
    "content_4_4_2": "用户可以免费使用付费套餐1个月。 如果您在免费试用期结束前未取消，一个月后将自动付款，并按月或按年续订。 免费期限可能会根据 adobaro 内部政策进行更改或删除。",
    "content_4_4_3": "即使用户在付费套餐续订期间没有使用服务，使用期限也不会被延长或退款。",
    "content_4_4_4": "付费计划是按月或按年计费，这意味着除非您取消订阅，否则您将继续付费。 用户取消订阅的话，后续责任自负。 通过电子邮件或通过客户中心申请取消订阅，将不被认可。 您可以随时在套餐plan页面取消订阅。",
    "content_4_4_5": "即使用户在付费套餐结束前取消付费套餐，服务仍将在付费套餐的剩余期限内继续维持。",
    "content_4_4_6": "每月或每年已支付的费用不予退还。 如果您需要支付自动续订服务的费用，则必须取消订阅，这样从下一个付款日期开始就不会扣费。",
    "content_4_4_7": "每个计划的好处和价格",
    "content_4_4_8": "每个计划的优惠和折扣率的价格可能会因内部情况而发生变化。",
    "plan_project": "项目",
    "plan_free": "Free（免费）",
    "plan_basic": "Basic（基础）",
    "plan_pro": "Pro（高级）",
    "plan_original": "原价",
    "plan_price": "价格",
    "plan_discounts": "优惠",
    "plan_discounts_prices": "优惠后价格",
    "plan_special_offer": "优惠活动",
    "plan_month": "月费",
    "plan_year": "年费",
    "plan_special_des": "注册后可免费使用 30 天（仅限 1 个计划，每个帐户一次）",
    "plan_content_filter": "内容预筛选",
    "plan_open_platform": "可开通平台数",
    "plan_upload_count": "上传次数",
    "plan_upload_limit_free_1": "每周一次",
    "plan_upload_limit_free_2": "Global PASS认证后",
    "plan_upload_limit_free_3": "每周2次",
    "plan_upload_limit_basic": "各平台每天一次",
    "plan_upload_limit_pro": "无限量",
    "plan_upload_limit_pro_a": "（每个平台每天最多5次）",
    "plan_upload_store": "每次上传容量",
    "plan_copyright": "版权保护（防止非法搬运）",
    "plan_charge": "结算手续费",
    "plan_storage": "提供存储容量空间",
    "plan_profitability": "可收益",
    "plan_account": "账户所有权",
    "plan_chinese_title": "中文标题优化",
    "plan_cover": "封面优化",
    "plan_tag": "标签话题优化",
    "plan_appointment": "预约上传",
    "plan_upload_simul": "各平台同时上传",
    "plan_plus_v": "各平台加 v 身份验证",
    "content_4_5": "积分使用规则",
    "content_4_5_1": "使用adobaro服务时满足特定条件即可获得积分。 支付的积分不能兑换现金，但可以用于购买网站提供的部分服务。",
    "content_4_5_2": "支付的积分可能会根据adobaro的运营政策发生变化，并且使用情况也可能会有所调整。",
    "content_4_6": "付费计划取消和退款政策",
    "content_4_6_1": "用户可以随时取消其付费套餐。 当您取消付费计划时，该计划的自动续订功能将被禁用。",
    "content_4_6_2": "如果您取消付费计划，我们将不会再次向您收取费用，并且付费计划的权益将保留到计费周期的最后一天。",
    "content_4_6_3": "从您取消付费计划到付费计划结束期间，不予退款。",
    "content_4_6_4": "如果您在付款超过14天后取消，则不予退款，服务将持续至合同期结束。",
    "content_4_6_5": "虽然付款14天后将不予退款，但取消后，剩余付费套餐在剩余使用期限结束后将不会进行扣费或续订。",
    "content_5": "第三方链接",
    "content_5_1": "adobaro服务可能会提供与其他国际互联网网站或资源进行链接。除非另有声明，adobaro无法对第三方网站之服务进行控制，用户因使用或依赖上述网站或资源所产生的损失或损害，adobaro不承担任何责任。我们建议您在离开adobaro，访问其他网站或资源前仔细阅读其服务条款和隐私政策。",
    "content_6": "知识产权",
    "content_6_1": "受国际版权公约、中华人民共和国著作权法、专利法、及其他知识产权方面的法律法规的保护，adobaro服务及本服务所使用的软件、技术、商标、材料等的所有知识产权归adobaro所有和享有。“知识产权”包括在专利法、著作权法、商标法、反不正当竞争法等法律中规定的任何和所有权利、所有权和利益，以及其中的任何和所有应用、更新、扩展和恢复。",
    "content_6_2": "用户不得修改、改编、翻译adobaro服务所使用的软件、技术、材料等，或者创作与之相关的派生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，adobaro将依法追究违约方的法律责任。",
    "content_6_3": "用户不得恶意修改、复制、传播adobaro服务所使用的软件、技术、材料等。否则，用户自行承担因此而造成对其他人的损害，或者造成对adobaro公司形象损害，要承担相应的法律责任。",
    "content_6_4": "用户不得擅自删除、掩盖或更改adobaro的版权声明、商标或其它权利声明。adobaro所有设计图样以及其他图样、产品及服务名称，均为adobaro所享有的商标、标识。任何人不得使用、复制或用作其他用途。",
    "content_6_5": "adobaro对其自制内容和其他通过授权取得的独占内容享有完全知识产权，未经adobaro许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯adobaro知识产权的行为。否则，adobaro将追究侵权行为人的法律责任。",
    "content_6_6": "adobaro所有和享有的知识产权，不因用户的任何使用行为而发生权利转移。",
    "content_7": "免责声明",
    "content_7_1": "adobaro对于任何包含、经由或连接、下载或从任何与有关本网络服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，adobaro不负保证责任。用户自行负担使用本服务的风险。",
    "content_7_2": "除非另有明确的书面说明，adobaro提供给您的全部产品和服务，均是在“按现状”和“按现有”的基础上提供的。",
    "content_7_3": "adobaro对如下事项不做担保（包括但不限于）：",
    "content_7_3_1": "adobaro提供的网站、客户端等软件虽然均已经过adobaro测试，但由于技术本身的局限性，adobaro不能保证其与其他软硬件、系统完全兼容。如果出现不兼容的情况，用户可将情况报告adobaro，以获得技术支持。如果无法解决问题，用户可以选择卸载、停止使用adobaro服务。",
    "content_7_3_2": "使用adobaro服务涉及到Internet服务，可能会受到各个环节不稳定因素的影响。因不可抗力、黑客攻击、系统不稳定、网络中断、用户关机、通信线路等原因，均可能造成adobaro服务中断或不能满足用户要求的情况。adobaro不保证adobaro服务适合用户的使用要求。",
    "content_7_3_3": "由于adobaro提供的客户端等软件可以通过网络途径下载、传播，因此对于从非adobaro指定官方站点下载、非adobaro指定途径获得的adobaro服务相关软件，adobaro无法保证其是否感染计算机病毒、是否隐藏有伪装的木马程序等黑客软件，也不承担用户由此遭受的一切直接或间接损害赔偿等法律责任。",
    "content_7_3_4": "adobaro不做任何与adobaro服务、产品的安全性、可靠性、及时性和性能有关的担保。",
    "content_7_3_5": "adobaro不保证其提供的任何产品、服务或其他材料符合用户的期望。",
    "content_7_4": "用户使用经由adobaro服务下载或取得的任何资料，其风险由用户自行负担，因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任。",
    "content_7_5": "基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，adobaro不承担任何直接、间接、附带、衍生或惩罚性的赔偿：",
    "content_7_5_1": "adobaro服务全部或部分无法使用；",
    "content_7_5_2": "经由adobaro服务购买或取得的任何产品、资料或服务；",
    "content_7_5_3": "用户资料遭到未授权的使用或修改；",
    "content_7_5_4": "其他与adobaro服务相关的事宜。",
    "content_7_6": "用户应妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，adobaro不承担补偿责任。用户因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定、不可抗力（如服务器宕机）等非adobaro原因造成账号、账号内财产等丢失、减少的，adobaro不承担补偿等责任。",
    "content_7_7": "用户理解并同意自主选择免费下载和使用adobaro服务，风险自负，包括但不限于用户使用adobaro服务过程中的行为，以及因使用adobaro服务产生的一切后果。如因下载或使用adobaro服务而对计算机系统造成的损坏或数据的丢失等，用户须自行承担全部责任。",
    "content_7_8": "用户因认证信息不真实而导致账号、账号内财产等丢失、减少而无法找回的，adobaro不承担任何法律责任。",
    "content_7_9": "adobaro相关平台服务如因系统维护或升级等而需暂停服务时，将事先公告。若因硬件故障或其他不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，adobaro不负任何责任。由于adobaro的调整导致信息丢失和/或其他结果的，adobaro不承担任何责任。中国平台因暂停服务时，adobaro将根据该平台提前通知的内容及时告知您，因中国平台系统暂停服务导致的不便或损失，adobaro不承担任何责任。",
    "content_8": "服务的变更、中断、终止",
    "content_8_1": "您理解并同意，adobaro基于经营策略的调整，可能会对服务内容进行变更，也可能会中断、中止或终止服务。",
    "content_8_2": "您理解并同意，如adobaro发生合并、分立、收购、资产转让时，adobaro可向第三方转让本服务下相关资产；adobaro亦可在单方通知您后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。",
    "content_8_3": "您理解并同意，如您在使用adobaro服务时存在违反法律法规、本协议及其他adobaro规则、社会公德、公序良俗和/或侵害他人合法权益等情形，adobaro有权不经通知而单方中断或终止向您提供全部或部分服务。",
    "content_8_4": "您理解并同意，如您实施违反法律法规、社会公德、公序良俗和/或侵害他人合法权益等行为，并因此影响或可能影响adobaro和/或他人的名誉、声誉或其他合法权益，adobaro有权不经通知而单方中断或终止向您提供全部或部分服务。",
    "content_8_5": "adobaro终止向您提供服务后，有权根据适用法律的要求删除您的个人信息，或使其匿名化处理，亦有权依照法律规定的期限和方式继续保存您留存于我方平台的其他内容和信息。",
    "content_9": "注销",
    "content_9_1": "用户有权向adobaro提出账号注销申请，您可以通过联系adobaro客服注销您的账号（法律法规、本协议或其他adobaro规则另有规定的除外）。",
    "content_9_2": "特别提醒：注销adobaro账号后，您将无法再以此账号登录和使用adobaro的所有产品与服务以及产品及服务中与第三方合作的服务内容，adobaro也将同时终止在该账号下为您提供我们各项产品与服务，这同时也不可避免地会给您的售后维权带来不便。且adobaro账号一旦注销完成，将无法恢复。请您在注销前慎重考虑。",
    "content_9_3": "如您确定需要注销adobaro账号的，您已充分知晓并确认，账号注销后该UID仍然存在，但您将不再拥有账号相关的权益，包括但不限于：",
    "content_9_3_1": "账号注销后，您将无法再以该账号登录、使用adobaro的全部产品和服务；",
    "content_9_3_2": "账号注销后，您曾通过该账号登录、使用adobaro的全部产品和服务的所有内容、信息、数据、记录将会被删除或匿名化处理，您也无法再搜索、访问、获取、使用和找回，包括但不限于：开通过平台的账号信息（头像、昵称、签名等）、绑定信息；",
    "content_9_3_3": "账号注销后，该账号的交易记录将被清空且无法恢复；",
    "content_9_3_4": "账号注销后，adobaro有权不再为用户提供任何与账号有关的服务；",
    "content_9_3_5": "在您向我们申请注销adobaro账号之前，为了保护您的账号安全和财产权益，您需先行检查与确保您申请注销的账号已经同时满足以下条件，包括但不限于：",
    "content_9_4": "在您向我们申请注销adobaro账号之前，为了保护您的账号安全和财产权益，您需先行检查与确保您申请注销的账号已经同时满足以下条件，包括但不限于：",
    "content_9_4_1": "账号系用户通过官方渠道注册，符合本协议及相关规定的账号；且为您本人的会员账号；",
    "content_9_4_2": "按照客服要求的注销流程进行注销操作；",
    "content_9_4_3": "账号处于安全状态，包括：未处于申请找密码、修改手机号的状态中；无未处理完毕的（被）投诉、举报；其他不安全/异常状态；",
    "content_9_4_4": "账号内无未处理完毕的交易；",
    "content_9_4_5": "账号内无您发起的但尚未完成的提现；",
    "content_9_4_6": "账号与adobaro全部业务都不存在合约关系或尚在合约期的（如签约主播等）；",
    "content_9_4_7": "如账号曾开通了其他服务的，您已经自行取消该服务；",
    "content_9_4_8": "其他应满足的条件。",
    "content_9_5": "如您已充分阅读并理解9.2和9.3条的内容，并确认已经满足9.4条的全部条件，可以向客服提出账号注销申请。客服将对您的账号安全状态以及您的相关产品与服务的使用情况等进行审核，综合判断您的账号是否符合条件。",
    "content_9_6": "adobaro账号一旦注销，您与我们曾签署过的相关用户协议、其他权利义务性文件等相应终止（但已约定继续生效的或法律另有规定的除外）。同时，您知悉并同意：即使您的账号被注销，也并不减轻或免除您在协议期间内应根据相关法律法规、相关协议、规则等（可能）需要承担的相关责任。以及，您的账号注销之后，您在adobaro中使用您的手机号开通的所有中国平台的账号都将注销，您将无法通过adobaro将您的内容发布至中国平台中，并且从申请注销账号成功的时刻开始，您在adobaro中的所有收益都无法进行提现，由此产生的adobaro以及平台账号的收益等损失由您自行承担，adobaro在您注销后不承担任何退赔。",
    "content_10": "隐私政策",
    "content_10_1": "adobaro注重保护用户的个人信息及个人隐私。个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。您在下载、安装、启动、浏览、注册、登录、使用adobaro的产品与/或服务时，adobaro将按照平台公布的《adobaro隐私政策》的约定处理和保护您的个人信息，因此希望您能够仔细阅读、充分理解《adobaro隐私政策》的全文，并在需要时，按照《adobaro隐私政策》的指引，作出您认为适当的选择。",
    "content_10_2": "您应当在仔细阅读、充分理解《adobaro隐私政策》后使用adobaro的产品与/或服务，如果您不同意政策的内容，将可能导致adobaro的产品与/或服务无法正常运行，或者无法达到adobaro拟达到的服务效果。您使用或继续使用adobaro提供的产品与/或服务的行为，都表示您充分理解和同意《adobaro隐私政策》（包括更新版本）的全部内容。",
    "content_10_3": "如您对《adobaro隐私政策》或对您的个人信息相关内容有任何问题（包括问题咨询、投诉等），您可通过《adobaro隐私政策》公布的联系方式联系我们。",
    "content_11": "未成年人条款",
    "content_11_1": "adobaro非常注重未成年人的保护。若您为未成年人，应在法定监护人监护、指导下阅读本协议，并在取得法定监护人的同意后使用adobaro的产品与/或服务。",
    "content_11_2": "法定监护人应指导子女上网应该注意的安全问题，防患于未然。adobaro不鼓励未成年人使用adobaro的产品/服务进行任何消费行为，如有消费，未成年人应以法定监护人名义申请消费或在法定监护人明示同意下操作。未成年人使用adobaro服务的行为即视为已获得了法定监护人认可。",
    "content_11_3": "adobaro提醒未成年人在使用adobaro服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。",
    "content_11_4": "对于未成年人的隐私保护，adobaro将严格按照平台公布的《adobaro隐私政策》 中阐述的方式、方法执行。",
    "content_12": "法律适用和管辖",
    "content_12_1": "本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。本条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。双方同意，解决争议时，应以您同意的最新版《adobaro用户使用协议》为准。",
    "content_12_2": "如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方均一致同意将争议提交adobaro仲裁委员会按照其仲裁规则进行仲裁。仲裁裁决为一裁终局，对各方均有法律约束力。",
    "content_12_3": "为免疑义，除另有相反书面约定外，本条法律适用和管辖条款效力及于本协议及其他adobaro规则，包括其修订、补充及更新。",
    "content_13": "协议的修改与通知",
    "content_13_1": "adobaro有权依据国家政策、技术条件、产品功能等变化需要而对本协议进行修改并将修改后的协议予以发布。",
    "content_13_2": "前述内容一经正式发布，adobaro将以适当的方式（包括但不限于弹窗、邮件、站内信、网站公告等）提醒您更新的内容，以便您及时了解本协议的最新版本。",
    "content_13_3": "您知悉，本协议的名称、各章节标题仅为方便及阅读而设，且因本协议在我们平台展示之处较多，全部协议更名完毕可能需要一段时间，在此期间内，两个名称的同时存在并不影响正文其中任何条款的含义或解释。",
    "content_13_4": "修改后的内容将构成本协议不可分割的组成部分，您应同样遵守。您对修改后的协议有异议的，请立即停止登录、使用adobaro及相关服务，若您登录或继续使用adobaro及相关服务，则视为您已充分阅读、理解并接受更新后的本协议并愿意受更新后的本协议的约束。",
    "content_13_5": "我们将本用户协议的内容翻译为其他语言供您阅读，该翻译文本仅作为参考，当翻译版本与本用户协议中文版本不一致时，应当以中文版本为准。"
  }
};export default zh